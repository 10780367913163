import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useForm } from "react-hook-form"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Button, FileSelect, Input } from "../components/inputs"
import { Icon, Message, PageTitle } from "../components/display"
import { useRequest } from "ahooks"

const submitCareers = ({ name, email, phone, location, resume }) => {
  const formData = new FormData()
  formData.append("form-name", "careers")
  formData.append("name", name)
  formData.append("email", email)
  formData.append("phone", phone)
  formData.append("location", location)
  formData.append("file", resume)
  return fetch("/", {
    method: "POST",
    body: formData,
  })
}

const PerkCard = ({ title, description, image }) => (
  <div className="w-full md:w-1/3 md:max-w-xs p-4">
    <div className="flex flex-col items-center bg-silver-light rounded-2xl py-8 px-10 h-full">
      <div className="pb-4">
        <div className="rounded-full bg-jet w-24 h-24 p-2">
          <Icon name={image} className="text-gold w-full h-12 mx-auto my-4" />
        </div>
      </div>
      <span className="text-xl font-bold font-display py-3">{title}</span>
      <span className="text-jet-light text-justify">{description}</span>
    </div>
  </div>
)

const CareerForm = ({ data }) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm()
  const [submitted, setSubmitted] = useState(false)
  const [submitErrored, setSubmitErrored] = useState(false)
  const { loading, run } = useRequest(submitCareers, {
    manual: true,
    onSuccess: result => {
      if (result.ok) {
        setSubmitErrored(false)
        setSubmitted(true)
        reset()
      } else {
        setSubmitErrored(true)
        setSubmitted(false)
      }
    },
  })

  return (
    <form
      name="careers"
      data-netlify="true"
      method="POST"
      onSubmit={handleSubmit(data => run(data))}
    >
      <input type="hidden" name="form-name" value="careers" />
      <div className="mb-4">
        <Input
          placeholder="Name (Required)"
          type="text"
          light
          errored={!!errors.name}
          {...register("name", { required: true })}
        />
      </div>
      <div className="mb-4">
        <Input
          placeholder="E-mail (Required)"
          type="email"
          light
          errored={!!errors.email}
          {...register("email", {
            required: true,
            pattern:
              /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          })}
        />
      </div>
      <div className="mb-4">
        <Input
          placeholder="Phone (Required)"
          type="tel"
          errored={!!errors.phone}
          light
          {...register("phone", {
            required: true,
            pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/i,
          })}
        />
      </div>
      <div className="mb-4">
        <Input
          placeholder="Location Preference"
          type="text"
          errored={!!errors.location}
          light
          {...register("location")}
        />
      </div>
      <div className="mb-4">
        <FileSelect
          name="resume"
          placeholder="Upload Resume (Required)"
          light
          errored={!!errors.resume}
          control={control}
        />
      </div>
      <div className="flex justify-between items-center">
        <label
          className={`${errors.accept_terms ? "ring-semantic-red ring-2" : ""}`}
        >
          <input
            className="mr-1"
            type="checkbox"
            {...register("accept_terms", { required: true })}
          />
          <span className="text-sm font-semibold">
            I agree to{" "}
            <Link className="text-gold" to={data.terms_and_conditions.href}>
              terms and conditions.
            </Link>
          </span>
        </label>
        <Button size="large" type="submit" disabled={loading || submitted}>
          {loading ? "Submitting" : submitted ? "Submitted" : "Submit"}
        </Button>
      </div>
      {submitErrored && (
        <Message className="mt-4" type="error">
          Cannot submit your resume and details now.
        </Message>
      )}
    </form>
  )
}

const Careers = ({ data, location }) => {
  const { seo, title, descriptionTexts, image, form, perks } = data.siteYaml

  return (
    <Layout location={location} title={title}>
      <Seo title={seo.title} description={seo.description} />
      <section className="py-20">
        <div className="container px-4 mx-auto">
          <PageTitle>{title}</PageTitle>
          <div className="pb-12">
            <div className="w-full mb-8 px-2">
              <GatsbyImage
                className="max-w-full rounded-xl"
                image={getImage(image)}
                alt={title}
              />
            </div>
            <div className="max-w-2xl px-5 py-5 mx-auto rounded-xl">
              <Icon
                name="employees.inline.svg"
                className="text-jet w-full h-12 mx-auto my-4"
              />
              {descriptionTexts.map(description => (
                <p
                  key={description}
                  className="text-lg font-medium pb-2 text-justify"
                >
                  {description}
                </p>
              ))}
            </div>
          </div>
          <div className="flex flex-wrap justify-center items-stretch pb-16">
            {perks.map(({ title, description, image }) => (
              <PerkCard
                key={title}
                title={title}
                description={description}
                image={image}
              />
            ))}
          </div>
          <div className="flex flex-col flex-wrap w-full items-center">
            <div className="w-full px-3 max-w-4xl">
              <div className="px-4">
                <h2 className="mt-2 text-2xl font-bold font-display text-center">
                  {form.title}
                </h2>
                <p className="mt-4 mb-8 text-jet-light text-center">
                  {form.description}
                </p>
              </div>
            </div>
            <div className="w-full px-3 max-w-4xl">
              <CareerForm data={form} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Careers

export const pageQuery = graphql`
  query {
    siteYaml(type: { eq: "careers" }) {
      seo {
        title
        description
      }
      title
      descriptionTexts
      image {
        childImageSharp {
          gatsbyImageData(width: 1200)
        }
      }
      form {
        description
        title
        terms_and_conditions {
          href
        }
      }
      perks {
        description
        title
        image
      }
    }
  }
`
